import React from 'react';
import { Settings } from 'react-slick';
import dynamic from 'next/dynamic';
import LazyLoad, { LazyLoadProps } from 'react-lazyload';
import { useIsClient } from './hooks/useIsClient';

const DynamicSlider = dynamic(() => import('react-slick'));
export const NextSlider: React.FC<
  Settings & {
    lazyHeight?: LazyLoadProps['height'];
  }
> = ({ responsive, lazyHeight, ...props }) => {
  const isClient = useIsClient();
  return (
    <LazyLoad height={lazyHeight}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DynamicSlider {...props} responsive={isClient ? responsive : undefined} key={isClient ? 'client' : 'server'} />
    </LazyLoad>
  );
};
