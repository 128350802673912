import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import Image from 'next/image';
import classNames from 'classnames';
import { MediaNews, mediaNews } from './mediaNews';
import { NextSlider } from '../../../components/NextSlider';

export const IndexTalkingAboutUsSliderElement: React.FC<{
  news: MediaNews;
}> = React.memo(({ news }) => {
  return (
    <a
      href={news.href}
      target="_blank"
      rel="noopener noreferrer nofollow"
      className={classNames('d-flex align-items-center justify-content-center talking-about-us-slider-element px-3', {
        inverse: news.inverse
      })}
      style={{
        paddingTop: 53,
        paddingBottom: 53
      }}
    >
      <div className="position-relative h-100 w-100">
        <Image
          src={news.logo}
          alt={news.name}
          className="img-fluid"
          fill
          sizes="100vw"
          unoptimized
          style={{
            objectFit: 'contain'
          }}
        />
      </div>
    </a>
  );
});

export const IndexTalkingAboutUsSlider: React.FC = React.memo(() => {
  return (
    <NextSlider
      arrows={false}
      dots={false}
      centerMode
      // autoplay
      className="center talking-about-us-slider mt-4"
      infinite
      centerPadding="60px"
      slidesToShow={5}
      swipeToSlide
      responsive={[
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 4,
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            centerPadding: '100px'
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2,
            centerPadding: '60px'
          }
        },
        {
          breakpoint: 400,
          settings: {
            slidesToShow: 1,
            centerPadding: '30px'
          }
        }
      ]}
    >
      {mediaNews.map((news) => (
        <IndexTalkingAboutUsSliderElement news={news} key={news.href} />
      ))}
    </NextSlider>
  );
});

export const IndexTalkingAboutUs: React.FC = React.memo(() => {
  return (
    <div className="talking-about-us mb-5 overflow-hidden mw-100">
      <Container className="mt-5">
        <Row>
          <Col>
            <div className="smaller-header text-center">
              <FormattedMessage id="home.talking.about-us" defaultMessage="Trusted by Tech Industry Experts" />
            </div>
          </Col>
        </Row>
      </Container>
      <IndexTalkingAboutUsSlider />
    </div>
  );
});
