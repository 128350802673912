import React, { CSSProperties, useMemo, useRef, useState } from 'react';
import { useScrollPosition } from '../../../components/hooks/useScrollPosition';
import { useIsDesktop } from '../../../components/hooks/useDevice';

const getCurrentStep = (stepsCount: number, scrollY: number, height: number, heightMultiplier: number) => {
  return Math.min(stepsCount - 1, Math.max(0, (-1 * scrollY * stepsCount) / (height - height / heightMultiplier)));
};

interface CarouselFullPageProps {
  children: (currenStep: number) => React.ReactNode;
  steps: number;
}

export const CarouselFullPage: React.FC<CarouselFullPageProps> = ({ children, steps }) => {
  const [scrollY, setScrollY] = useState<number>(0);
  const [height, setHeight] = useState<number>(1);
  const viewportRef = useRef<HTMLDivElement>(null);
  const isDesktop = useIsDesktop();

  const heightMultiplier = isDesktop ? 4 : 2.5;
  const currentStep = useMemo(
    () => getCurrentStep(steps, scrollY, height, heightMultiplier),
    [steps, scrollY, height, heightMultiplier]
  );
  useScrollPosition((info) => {
    const ref = viewportRef.current;
    if (!ref) {
      return;
    }

    const dom = ref.getBoundingClientRect();
    const h = dom.height;
    setScrollY(info.y);
    setHeight(h);
  }, viewportRef);

  const containerClassname: CSSProperties = {
    alignItems: 'flex-start'
  };
  return (
    <div
      className="d-flex my-5"
      style={{ height: `${heightMultiplier * 100}vh`, ...containerClassname }}
      ref={viewportRef}
    >
      <div
        style={{
          height: `${100 / heightMultiplier}%`,
          position: `sticky`,
          top: 0
        }}
        className="carousel-full-page"
      >
        {children(currentStep)}
      </div>
    </div>
  );
};
