export interface Quote {
  message: string;
  name: string;
  stars: 1 | 2 | 3 | 4 | 5;
  store: 'google' | 'apple';
}

export const premiumQuotes: Quote[] = [
  {
    name: 'figja',
    message:
      'Great and simple way to move my library across to Spotify. Years of work to build a great library and was feeling locked in to one provider in case I lost it all. Thanks for freeing my music lists.',
    stars: 5,
    store: 'apple'
  },
  {
    message:
      'Excellent, works perfect. Transferred my amazon music to youtube music without a hitch. Happy with purchase',
    name: 'Billy McLaughlan',
    stars: 5,
    store: 'google'
  },
  {
    message:
      "Can't tell you how much I love this app. Transferred about 95% of my 6,000 tracks from Spotify to Apple Music in a couple of hours. Saved me days of hard work doing it manually. Highly recommended.",
    name: 'Neil Symington',
    stars: 5,
    store: 'apple'
  }
  //   {
  //     message:
  //       'I love it, helped me a lot <3\n' +
  //       'The app is easy to use, it worked for every streaming service i used and really helped me migrate from one to another :))\n' +
  //       "I especially like the 'life-time-subscription'. I think it's great to pay and forget and not worrying about monthly subscriptions.\n" +
  //       'Thank You!!',
  //     name: 'Korbi05',
  //     stars: 5,
  //     store: 'apple'
  //   },
  //   {
  //     message:
  //       'A great app for migrating between music services. I have transferred thousands of tracks in multiple playlists at once. Transferred between Spotify and Amazon/Google with no issues.',
  //     name: 'Chris Beavis',
  //     stars: 5,
  //     store: 'google'
  //   },
  //   {
  //     message: `Awesomeness
  // Works as advertised and saved my music!`,
  //     name: 'stratocaster3374',
  //     stars: 5,
  //     store: 'apple'
  //   },
  //   {
  //     message: `User friendly
  // Very easy to use, step by step!`,
  //     name: 'GilouMTL',
  //     stars: 5,
  //     store: 'apple'
  //   },
  //   {
  //     message: 'Saves tons of time and mental energy. Worth every penny.',
  //     name: 'Treven Keeler',
  //     stars: 5,
  //     store: 'google'
  //   },
  //   {
  //     message:
  //       'Great Service and Hook\n' +
  //       'Great service with quality transfer. Very user friendly. Also, a great hook to keep you involved with providing you with a gift. Will definitely be using the app very often!',
  //     name: 'LJO10__',
  //     stars: 5,
  //     store: 'apple'
  //   }
];
