import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { NavbarComponent } from '../../../layouts/Navbar';

export enum TopComponentSize {
  Default,
  Narrow,
  FullWidth
}

function getXSForSize(size: TopComponentSize): number {
  switch (size) {
    default:
    case TopComponentSize.FullWidth:
    case TopComponentSize.Default:
      return 12;
    case TopComponentSize.Narrow:
      return 9;
  }
}

function getSMForSize(size: TopComponentSize): number {
  switch (size) {
    default:
    case TopComponentSize.FullWidth:
      return 12;
    case TopComponentSize.Default:
      return 10;
    case TopComponentSize.Narrow:
      return 9;
  }
}

export const TopComponent: React.FC<
  React.PropsWithChildren<{
    size?: TopComponentSize;
    navbarComponent?: React.ReactNode;
    ContainerClass?: React.ComponentType;
  }>
> = React.memo(({ children, size = TopComponentSize.Default, navbarComponent, ContainerClass = Container }) => {
  return (
    <div className="index-container waves-bg d-flex flex-column">
      {navbarComponent ?? <NavbarComponent />}
      <div className="flex-grow-1 d-flex flex-column justify-content-center home-top">
        <ContainerClass className="text-center">
          <Row>
            <Col xs={getXSForSize(size)} sm={getSMForSize(size)} className="mx-auto">
              {children}
            </Col>
          </Row>
        </ContainerClass>
      </div>
    </div>
  );
});
