import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Image, { StaticImageData } from 'next/image';
import { Col, Container, Row } from 'reactstrap';
import { MobileScreenshots } from '../../../components/MobileScreenshots';
import { LocalizedString } from '../../../components/types/LocalizedString';
import Move1DarkImage from '../../../../public/statics/images/move/1-dark.svg';
import Move1LightImage from '../../../../public/statics/images/move/1-light.svg';
import Move2DarkImage from '../../../../public/statics/images/move/2-dark.svg';
import Move2LightImage from '../../../../public/statics/images/move/2-light.svg';
import Move3DarkImage from '../../../../public/statics/images/move/3-dark.svg';
import Move3LightImage from '../../../../public/statics/images/move/3-light.svg';
import Move4DarkImage from '../../../../public/statics/images/move/4-dark.svg';
import Move4LightImage from '../../../../public/statics/images/move/4-light.svg';
import { screenshotAsset } from '../../../components/screenshotNextAsset';
import { AvailableLanguages } from '../../../redux/appTypes';

import {
  step1,
  step1AlbumsDesc,
  step1AllMusicDesc,
  step1Alt,
  step1Desc,
  step1LikedSongsDesc,
  step2,
  step2AlbumsDesc,
  step2AllMusicDesc,
  step2Alt,
  step2Desc,
  step2LikedSongsDesc,
  step3,
  step3Albums,
  step3AlbumsDesc,
  step3AllMusic,
  step3AllMusicDesc,
  step3Alt,
  step3Desc,
  step3LikedSongs,
  step3LikedSongsDesc,
  step4,
  step4AlbumsDesc,
  step4Alt,
  step4Desc,
  step4LikedSongsDesc,
  step4PlaylistsDesc
} from './messages';
import { MusicService } from '../../../components/musicServices/services/types';
import { useIsDesktop } from '../../../components/hooks/useDevice';

export interface MoveHowToStep {
  title: Record<MoveFromToHowToType, LocalizedString>;
  description: Record<MoveFromToHowToType, LocalizedString>;
  alt: LocalizedString;
  mobileScreenshot: {
    dark: (lang: AvailableLanguages | undefined) => StaticImageData;
    light: (lang: AvailableLanguages | undefined) => StaticImageData;
  };
  image: {
    dark: any;
    light: any;
  };
}

export enum MoveFromToHowToType {
  playlists = 'playlists',
  albums = 'albums',
  likedSongs = 'likedSongs',
  allMusicLibrary = 'allMusicLibrary'
}

export const moveHowToSteps: MoveHowToStep[] = [
  {
    title: {
      [MoveFromToHowToType.playlists]: step1,
      [MoveFromToHowToType.albums]: step1,
      [MoveFromToHowToType.likedSongs]: step1,
      [MoveFromToHowToType.allMusicLibrary]: step1
    },
    description: {
      [MoveFromToHowToType.playlists]: step1Desc,
      [MoveFromToHowToType.albums]: step1AlbumsDesc,
      [MoveFromToHowToType.likedSongs]: step1LikedSongsDesc,
      [MoveFromToHowToType.allMusicLibrary]: step1AllMusicDesc
    },
    alt: step1Alt,
    mobileScreenshot: {
      dark: (l) => screenshotAsset('mobile-screenshot-1-dark', l),
      light: (l) => screenshotAsset('mobile-screenshot-1-light', l)
    },
    image: {
      dark: Move1DarkImage,
      light: Move1LightImage
    }
  },
  {
    title: {
      [MoveFromToHowToType.playlists]: step2,
      [MoveFromToHowToType.albums]: step2,
      [MoveFromToHowToType.likedSongs]: step2,
      [MoveFromToHowToType.allMusicLibrary]: step2
    },
    description: {
      [MoveFromToHowToType.playlists]: step2Desc,
      [MoveFromToHowToType.albums]: step2AlbumsDesc,
      [MoveFromToHowToType.likedSongs]: step2LikedSongsDesc,
      [MoveFromToHowToType.allMusicLibrary]: step2AllMusicDesc
    },
    alt: step2Alt,
    mobileScreenshot: {
      dark: (l) => screenshotAsset('mobile-screenshot-2-dark', l),
      light: (l) => screenshotAsset('mobile-screenshot-2-light', l)
    },
    image: {
      dark: Move2DarkImage,
      light: Move2LightImage
    }
  },
  {
    title: {
      [MoveFromToHowToType.playlists]: step3,
      [MoveFromToHowToType.albums]: step3Albums,
      [MoveFromToHowToType.likedSongs]: step3LikedSongs,
      [MoveFromToHowToType.allMusicLibrary]: step3AllMusic
    },
    description: {
      [MoveFromToHowToType.playlists]: step3Desc,
      [MoveFromToHowToType.albums]: step3AlbumsDesc,
      [MoveFromToHowToType.likedSongs]: step3LikedSongsDesc,
      [MoveFromToHowToType.allMusicLibrary]: step3AllMusicDesc
    },
    alt: step3Alt,
    mobileScreenshot: {
      dark: (l) => screenshotAsset('mobile-screenshot-3-dark', l),
      light: (l) => screenshotAsset('mobile-screenshot-3-light', l)
    },
    image: {
      dark: Move3DarkImage,
      light: Move3LightImage
    }
  },
  {
    title: {
      [MoveFromToHowToType.playlists]: step4,
      [MoveFromToHowToType.albums]: step4,
      [MoveFromToHowToType.likedSongs]: step4,
      [MoveFromToHowToType.allMusicLibrary]: step4
    },
    description: {
      [MoveFromToHowToType.playlists]: step4PlaylistsDesc,
      [MoveFromToHowToType.albums]: step4AlbumsDesc,
      [MoveFromToHowToType.likedSongs]: step4LikedSongsDesc,
      [MoveFromToHowToType.allMusicLibrary]: step4Desc
    },
    alt: step4Alt,
    mobileScreenshot: {
      dark: (l) => screenshotAsset('mobile-screenshot-4-dark', l),
      light: (l) => screenshotAsset('mobile-screenshot-4-light', l)
    },
    image: {
      dark: Move4DarkImage,
      light: Move4LightImage
    }
  }
];

const MobileScreenshotComponent: React.FC<{
  s: MoveHowToStep;
  fromName: string;
  toName: string;
}> = ({ s, fromName, toName }) => {
  const intl = useIntl();
  const { locale } = useRouter();

  const intlValues = {
    from: fromName,
    to: toName
  };
  /* eslint-disable react/no-array-index-key */
  return (
    <div className="text-center d-flex justify-content-center align-items-center h-100 position-absolute">
      <div className="d-flex h-100 flex-row">
        <Image
          src={s.mobileScreenshot.dark(locale as AvailableLanguages | undefined)}
          alt={intl.formatMessage(s.alt, intlValues)}
          title={intl.formatMessage(s.alt, intlValues)}
          className="img-fluid"
          fill
          placeholder="blur"
          sizes="100vw"
          style={{
            objectFit: 'contain'
          }}
        />
      </div>
    </div>
  );
  /* eslint-enable react/no-array-index-key */
};

export const MoveFromToHowToSteps: React.FC<{
  from: MusicService;
  to: MusicService;
  currentStep: number;
  type: MoveFromToHowToType;
}> = React.memo(({ from, to, currentStep, type }) => {
  const intl = useIntl();
  const isDesktop = useIsDesktop();
  const intlValues = useMemo(
    () => ({
      from: <span style={{ color: from.textColor ?? from.color }}>{from.shortName}</span>,
      to: <span style={{ color: to.textColor ?? to.color }}>{to.shortName}</span>,
      MusicServiceNameFrom: from.name,
      MusicServiceNameTo: to.name
    }),
    [from.color, from.name, from.shortName, from.textColor, to.color, to.name, to.shortName, to.textColor]
  );

  const title = useMemo(() => {
    switch (type) {
      case MoveFromToHowToType.playlists:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.title',
            defaultMessage: 'How to transfer {br}{from} playlists to {to}'
          },
          { br: <br />, ...intlValues }
        );
      case MoveFromToHowToType.albums:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.albums-title',
            defaultMessage: 'How to transfer {br}{from} albums to {to}'
          },
          { br: <br />, ...intlValues }
        );
      case MoveFromToHowToType.likedSongs:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.liked_songs-title',
            defaultMessage: 'How to transfer {br}{from} liked songs to {to}'
          },
          { br: <br />, ...intlValues }
        );
      default:
      case MoveFromToHowToType.allMusicLibrary:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.all_music-title',
            defaultMessage: 'How to transfer {br}{from} music library to {to}'
          },
          { br: <br />, ...intlValues }
        );
    }
  }, [intl, intlValues, type]);

  const description = useMemo(() => {
    switch (type) {
      case MoveFromToHowToType.playlists:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.playlists-description',
            defaultMessage:
              'Ultra-quick tutorial for playlist transfers from {MusicServiceNameFrom} to {MusicServiceNameTo}.'
          },
          { br: <br />, ...intlValues }
        );
      case MoveFromToHowToType.albums:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.albums-description',
            defaultMessage: 'Easily transfer albums from {MusicServiceNameFrom} to {MusicServiceNameTo}.'
          },
          { br: <br />, ...intlValues }
        );
      case MoveFromToHowToType.likedSongs:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.liked_songs-description',
            defaultMessage:
              'Transfer liked songs quickly and easily from {MusicServiceNameFrom} to {MusicServiceNameTo} in four quick steps!'
          },
          { br: <br />, ...intlValues }
        );
      default:
      case MoveFromToHowToType.allMusicLibrary:
        return intl.formatMessage(
          {
            id: 'web.move.how-to-transfer.all_music-description',
            defaultMessage:
              'Transfer all of your music library from {MusicServiceNameFrom} to {MusicServiceNameTo} with these steps:'
          },
          { br: <br />, ...intlValues }
        );
    }
  }, [intl, intlValues, type]);
  return (
    <div className="h-100 w-100 d-flex flex-column how-to-container">
      <div className="flex-grow-1">
        {moveHowToSteps.map((s, index) => {
          const ImageComponentDark = s.image.dark;
          const calculatedMultiplier = Math.round(currentStep - index);
          return (
            <div
              key={s.title[type].id}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 100 + index,
                overflow: 'hidden'
              }}
              className={classNames('d-flex flex-row ')}
            >
              <div
                style={{
                  height: '100vh',
                  width: '100%'
                }}
                className="d-flex flex-column"
              >
                {index === moveHowToSteps.length - 1 ? (
                  <div className="d-flex flex-column bg-dark" style={{ height: 240 }}>
                    <h2 className="main-header text-center pt-5 pb-3">
                      {title}
                      <br />
                    </h2>
                    <div className="lead text-center pb-1">{description}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: 240,
                      width: '100%'
                    }}
                    className="bg-dark"
                  />
                )}
                <Container className="flex-grow-1 pb-5">
                  <Row className="h-100">
                    <Col xs={4} md={6} className="d-flex position-relative overflow-hidden">
                      <div
                        className="d-flex w-100"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          transition: '0.3s ease-in-out',
                          transform: `translateX(${calculatedMultiplier * -100}%)`
                        }}
                      >
                        <MobileScreenshots>
                          <MobileScreenshotComponent s={s} fromName={from.shortName} toName={to.shortName} />
                        </MobileScreenshots>
                      </div>
                    </Col>
                    <Col xs={8} md={6} className="d-flex flex-column justify-content-center">
                      <div
                        className="align-items-center d-flex flex-column"
                        style={{
                          transform: `translateY(${(isDesktop ? currentStep - index : calculatedMultiplier) * -100}%)`,
                          transition: isDesktop ? undefined : '0.3s linear'
                        }}
                      >
                        <div key={s.title[type].id} className={`how-to-step how-to-step-${index} bg-dark`}>
                          <ImageComponentDark alt={intl.formatMessage(s.title[type], intlValues)} />
                          <h3 className="mb-4">{intl.formatMessage(s.title[type], intlValues)}</h3>
                          <p>{intl.formatMessage(s.description[type], intlValues)}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
