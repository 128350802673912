import React from 'react';
import classNames from 'classnames';

export const MobileScreenshots: React.FC<React.PropsWithChildren<{ className?: string }>> = React.memo(
  ({ children, className }) => {
    return (
      <div className={classNames('mobile-screenshots', className)}>
        <div className="mobile-screenshots-screenshot">{children}</div>
      </div>
    );
  }
);
