import React, { useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useIntl } from 'react-intl';

export interface Quote {
  message: string;
  name: string;
  stars: 1 | 2 | 3 | 4 | 5;
  store: 'google' | 'apple';
}

export const QuotesSliderElement: React.FC<{
  quote: Quote;
}> = React.memo(({ quote }) => {
  const starsFull = useMemo(() => Array(quote.stars).fill(0), [quote.stars]);
  const starsEmpty = useMemo(() => Array(5 - quote.stars).fill(0), [quote.stars]);
  return (
    <div className="d-flex flex-column quotes-slider-element px-3">
      <div className="quotes-slider-element-content">
        <span className="text-gold fw-bold">&ldquo;</span>
        <span className="quotes-slider-element-message">{quote.message}</span>
        <span className="text-gold fw-bold">&rdquo;</span>
      </div>
      <div className="quotes-slider-element-author">
        {quote.store === 'google' ? (
          <i className="fa-brands fa-google-play" />
        ) : (
          <i className="fa-brands fa-app-store-ios" />
        )}{' '}
        {quote.name}
      </div>
      <div className="quotes-slider-element-bottom">
        <div className="quotes-slider-element-stars">
          {starsFull.map((v, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <i key={index} className="fa-solid fa-star star star-full" />
          ))}
          {starsEmpty.map((v, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <i key={index} className="fa-solid fa-star star star-empty" />
          ))}
        </div>
      </div>
    </div>
  );
});

export const QuotesView: React.FC<{
  quotes: Quote[];
}> = React.memo(({ quotes }) => {
  const intl = useIntl();
  return (
    <Container>
      <Row>
        <Col>
          <div className="mt-1 mt-lg-5 text-center">
            <div className="smaller-header mb-3 mx-auto">
              {intl.formatMessage({
                id: 'web.quotes.title',
                defaultMessage: 'See what people think about FreeYourMusic'
              })}
            </div>
            <p className="lead text-center">
              {intl.formatMessage(
                {
                  id: 'web.quotes.description',
                  defaultMessage:
                    'Users’ experience and feedback are invaluable to us – {br}check insights about the app, shared by music enthusiasts.'
                },
                { br: <br /> }
              )}
            </p>
          </div>
        </Col>
      </Row>
      <Row className="quotes">
        {quotes.map((quote) => (
          <QuotesSliderElement quote={quote} key={quote.name} />
        ))}
      </Row>
    </Container>
  );
});
