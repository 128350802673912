import { nextAsset } from '../../../components/nextAsset';

export interface MediaNews {
  key: string;
  name: string;
  logo: string;
  href: string;
  testimonial?: string;
  inverse?: boolean;
}

export const mediaNews: MediaNews[] = [
  {
    key: 'theverge',
    name: 'The Verge',
    logo: nextAsset('media-logos/the-verge.png'),
    href: 'https://www.theverge.com/22916495/playlist-transfer-spotify-youtube-music-amazon-apple-music',
    testimonial:
      'How to transfer playlists from Spotify to Apple Music, Amazon Music, YouTube Music, or Tidal (Feb 2022)'
  },
  {
    key: 'lifehacker',
    name: 'LifeHacker',
    logo: nextAsset('media-logos/lifehacker.svg'),
    href: 'https://lifehacker.com/transfer-your-music-library-and-playlists-among-any-str-1849125094',
    testimonial: 'Transfer Your Music Library and Playlists Among Any Streaming Services With This App (July 2022)'
  },
  {
    key: 'thenextweb',
    name: 'The Next Web',
    logo: nextAsset('media-logos/tnw-the-next-web.svg'),
    href: 'https://thenextweb.com/news/freeyourmusic-helps-make-your-music-playlists-truly-yours-everywhere-you-stream',
    testimonial: 'FreeYourMusic helps make your music playlists truly yours, everywhere you stream (July 2020)'
  },
  {
    key: 'androidpolice',
    name: 'Android Police',
    logo: nextAsset('media-logos/android-police.svg'),
    href: 'https://www.androidpolice.com/how-to-move-from-spotify-to-another-music-service/',
    testimonial: 'How to move from Spotify to another music service like Apple Music or YouTube Music (Feb 2022)'
  },
  {
    key: '9to5mac',
    name: '9to5Mac',
    logo: nextAsset('media-logos/9to5-mac.svg'),
    href: 'https://9to5mac.com/2022/01/30/how-to-transfer-spotify-apple-music/',
    testimonial: 'How to transfer Spotify songs to Apple Music, and more (April 2021)'
  },
  {
    key: 'macworld',
    name: 'MacWorld',
    logo: nextAsset('media-logos/mac-world.svg'),
    href: 'https://www.macworld.com/article/671963/how-to-import-spotify-playlists-into-apple-music.html',
    testimonial: 'How to import Spotify playlists into Apple Music (Feb 2022)',
    inverse: true
  },
  {
    key: 'theguardian',
    name: 'The Guardian',
    logo: nextAsset('media-logos/the-guardian.svg'),
    href: 'https://www.theguardian.com/technology/2022/feb/01/spotify-alternatives-joni-mitchell-neil-young',
    testimonial: 'Here’s how to break up with Spotify'
  },
  {
    key: 'androidauthority',
    name: 'Android Authority',
    logo: nextAsset('media-logos/android-authority.webp'),
    href: 'https://www.androidauthority.com/transfer-spotify-playlists-apple-music-3073455/',
    testimonial: 'How to transfer Spotify playlists to Apple Music'
  },
  {
    key: 'macworld2',
    name: 'MacWorld',
    logo: nextAsset('media-logos/mac-world.svg'),
    href: 'https://www.macworld.com/article/610914/how-to-move-music-library-playlists-spotify-apple-music.html',
    testimonial: 'How to move your library and playlists from Spotify to Apple Music (Feb 2022)',
    inverse: true
  },
  {
    key: 'gearpatrol',
    name: 'Gear Patrol',
    logo: nextAsset('media-logos/gear-patrol.svg'),
    href: 'https://www.gearpatrol.com/tech/a428874/how-to-switch-spotify-to-apple-music/',
    testimonial: 'How to Switch Over from Spotify to Apple Music — It’s Easy (June 2022)',
    inverse: true
  },
  {
    key: 'businessinsider',
    name: 'Business Insider',
    logo: nextAsset('media-logos/business-insider.webp'),
    href: 'https://www.businessinsider.com/spotify-to-apple-music?IR=T',
    testimonial: 'How to painlessly switch from Spotify to Apple Music and transfer your playlists (Jan 2022)'
  },
  {
    key: 'techadvisor',
    name: 'Tech Advisor',
    logo: nextAsset('media-logos/tech-advisor.png'),
    href: 'https://www.techadvisor.com/article/740077/how-to-move-from-google-play-music-to-spotify.html',
    testimonial: 'How to move from Google Play Music to Spotify (July 2020)'
  },
  {
    key: 'wired',
    name: 'Wired',
    logo: nextAsset('media-logos/wired.svg'),
    href: 'https://www.wired.com/story/apple-music-spotify-playlists/',
    testimonial: 'Just Got Apple Music? Here’s How to Keep Your Spotify Playlists  (Jan 2022)',
    inverse: true
  }
];
